import React from 'react';
import packagingImage from '../assets/serve-hot.webp'; // Placeholder, replace with your actual image
import loveImage from '../assets/women1.webp'; // Placeholder, replace with your actual image
import serveImage from '../assets/package.webp'; // Placeholder, replace with your actual image

const services = [
  {
    id: 1,
    title: 'Automated Packaging',
    description: '100% environment-friendly packaging.',
    image: packagingImage,
  },
  {
    id: 2,
    title: 'Packed with Love',
    description: 'We make sure you get the best experience.',
    image: loveImage,
  },
  {
    id: 3,
    title: 'Serve hot Appetite',
    description: 'Delicious hot meals within 20 mins.',
    image: serveImage,
  },
];

const HowWeServe = () => {
  return (
    <section className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-extrabold text-gray-900">
          How we <span className="text-orange-600">Serve</span> you
        </h2>
        <div className="mt-10 grid gap-8 lg:grid-cols-3">
          {services.map((service) => (
            <div key={service.id} className="flex flex-col items-center text-center bg-gray-100 p-6 rounded-lg shadow-lg">
              <img src={service.image} alt={service.title} className="w-24 h-24 mb-4" />
              <h3 className="text-xl font-semibold text-gray-900">{service.title}</h3>
              <p className="mt-2 text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowWeServe;
