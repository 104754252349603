import React, { useState } from 'react';
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import pizzaImage from '../assets/women1.webp'; // Make sure to replace with your actual image path
import mobileAppImage from '../assets/download.webp'; // Make sure to replace with your actual image path
import profile1 from '../assets/women1.webp'; // Replace with actual paths
import profile2 from '../assets/women1.webp';
import profile3 from '../assets/women1.webp';
import profile4 from '../assets/women1.webp';
import profile5 from '../assets/women1.webp';
import './AppDownload.css';

const AppDownloadSection = () => {
    const [profiles] = useState([profile1, profile2, profile3, profile4, profile5]);

    return (
        <section className="bg-white py-10">
            <div className="container mx-auto flex clip flex-col lg:flex-row items-center justify-between px-4">
                <div className="lg:w-1/2 mb-8 lg:mb-0 mt-10">
                    <img src={mobileAppImage} alt="Mobile App" className="mobile rounded lg:w-2/3 mx-auto" />
                </div>
                <div className="lg:w-1/2 text-center lg:text-left">
                    <h2 className="text-3xl font-bold mb-4">Download our Mobile App</h2>
                    <div className="flex justify-center lg:justify-start space-x-2 mb-6">
                        {profiles.map((profile, index) => (
                            <img
                                key={index}
                                src={profile}
                                alt={`Profile ${index + 1}`}
                                className="w-10 h-10 rounded-full border-2 border-green -ml-2"
                            />
                        ))}
                    </div>
                    <div className="flex justify-center lg:justify-start space-x-4">
                        <a
                            href="#"
                            className="flex items-center bg-green  border-2 border-green text-white px-4 py-2 rounded-full  transition"
                        >
                            <FaGooglePlay className="text-xl mr-2" /> Play Store
                        </a>
                        <a
                            href="#"
                            className="flex items-center bg-green border-2 border-green-500 text-white px-4 py-2 rounded-full  transition"
                        >
                            <FaApple className="text-xl mr-2" /> App Store
                        </a>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block absolute right-0 top-0 mt-96">
                <img src={pizzaImage} alt="Pizza" className="w-48" />
            </div>
        </section>
    );
};

export default AppDownloadSection;

