import React from 'react';
import starbucksImage from '../assets/starbucks.webp'; // Placeholder, replace with your actual image
import dominosImage from '../assets/mcdonalds.webp'; // Placeholder, replace with your actual image
import mcdonaldsImage from '../assets/kfc.webp'; // Placeholder, replace with your actual image
// Add other restaurant images similarly...

const restaurants = [
    { id: 1, image: starbucksImage, name: 'Starbucks' },
    { id: 2, image: dominosImage, name: 'Domino\'s' },
    { id: 3, image: mcdonaldsImage, name: 'McDonald\'s' },
    // Add other restaurants similarly...
];

const RestaurantShowcase = () => {
    return (
        <section className="relative bg-green-600 text-white py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                <h2 className="text-4xl font-extrabold mb-4">
                    Enjoy <span className="text-yellow-400">3000+</span> foods from <span className="text-yellow-400">400</span> Restaurants
                </h2>
                <button className="mt-6 px-6 py-3 bg-black text-white font-semibold rounded-full shadow-lg hover:bg-gray-800">
                    Order Now
                </button>
                <div className="mt-10 flex flex-wrap justify-center gap-8">
                    {restaurants.map((restaurant) => (
                        <div key={restaurant.id} className="flex flex-col items-center">
                            <img src={restaurant.image} alt={restaurant.name} className="w-20 h-20 mb-2 rounded-full bg-white p-2" />
                            <span className="text-sm">{restaurant.name}</span>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default RestaurantShowcase;
