import React from 'react';
import saladImage from '../assets/salad.webp'; // Placeholder, replace with your actual image
import drinkImage from '../assets/drinks.webp'; // Placeholder, replace with your actual image
import friesImage from '../assets/fries.webp'; // Placeholder, replace with your actual image

const categories = [
    {
        id: 1,
        name: 'Salads',
        description: 'Fresh and healthy salads.',
        image: saladImage,
    },
    {
        id: 2,
        name: 'Drinks',
        description: 'Refreshing beverages.',
        image: drinkImage,
    },
    {
        id: 3,
        name: 'Fries',
        description: 'Crispy and delicious fries.',
        image: friesImage,
    },
];

const BestDeliveredCategories = () => {
    return (
        <section className="bg-white py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900">
                        Our <span className="text-green">Best Delivered</span> Categories
                    </h2>
                    <p className="mt-4 text-lg text-gray-600">
                        It's not just about bringing you good food from restaurants, we deliver you experience.
                    </p>
                </div>
                <div className="mt-10 grid gap-8 lg:grid-cols-3">
                    {categories.map((category) => (
                        <div key={category.id} className="flex flex-col items-center text-center bg-gray-100 p-6 rounded-lg shadow-lg">
                            <img src={category.image} alt={category.name} className="w-32 h-32 mb-4 rounded-full" />
                            <h3 className="text-xl font-semibold text-gray-900">{category.name}</h3>
                            <p className="mt-2 text-gray-600">{category.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default BestDeliveredCategories;
