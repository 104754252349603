import './App.css';
import AppDownloadSection from './components/AppDownloadSection';
import BestDeliveredCategories from './components/BestDeliveredCategories';
import FastestFoodDelivery from './components/FastestFoodDelivery';
import Header from './components/Header';
import HowWeServe from './components/HowWeServe';
import MainSection from './components/MainSection';
import PromotionalOffers from './components/PromotionalOffers';
import RestaurantShowcase from './components/RestaurantShowcase';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <MainSection></MainSection>
      <AppDownloadSection></AppDownloadSection>
      <BestDeliveredCategories></BestDeliveredCategories>
      <FastestFoodDelivery></FastestFoodDelivery>
      <HowWeServe></HowWeServe>
      <PromotionalOffers></PromotionalOffers>
      <RestaurantShowcase></RestaurantShowcase>
      <Footer></Footer>
    </div>
  );
}

export default App;
