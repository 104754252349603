import React from 'react';
import burgerImage from '../assets/tacos.webp'; // Placeholder, replace with your actual image
import wafflesImage from '../assets/waffles.webp'; // Placeholder, replace with your actual image
import tacosImage from '../assets/burger.webp'; // Placeholder, replace with your actual image

const offers = [
    {
        id: 1,
        title: 'Buy 2 Get 1 free',
        price: '$25',
        description: 'Limited time offer',
        image: burgerImage,
        bgColor: 'bg-yellow-500',
    },
    {
        id: 2,
        title: '$21',
        discount: 'Save 20%',
        image: wafflesImage,
        bgColor: 'bg-brown-500',
    },
    {
        id: 3,
        title: 'Tortilla wrap tacos',
        discount: '$12 off',
        description: 'Get your order fresh',
        image: tacosImage,
        bgColor: 'bg-red-500',
    },
];

const PromotionalOffers = () => {
    return (
        <section className="bg-white py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid gap-8 lg:grid-cols-3">
                {offers.map((offer) => (
                    <div key={offer.id} className={`flex flex-col items-center text-center ${offer.bgColor} p-6 rounded-lg shadow-lg`}>
                        <img src={offer.image} alt={offer.title} className="w-full h-32 mb-4 rounded-lg object-cover" />
                        <h3 className="text-2xl font-semibold text-gray-900 mb-2">{offer.title}</h3>
                        {offer.price && <span className="text-lg font-bold text-white bg-orange-600 p-2 rounded-full">{offer.price}</span>}
                        {offer.discount && <span className="text-lg font-bold text-white bg-orange-600 p-2 rounded-full">{offer.discount}</span>}
                        {offer.description && <p className="mt-2 text-gray-600">{offer.description}</p>}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default PromotionalOffers;
