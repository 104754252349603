import React from 'react';
import pizzaImage from '../assets/drone.webp'; // Placeholder, replace with your actual image
import droneImage from '../assets/pizza.webp'; // Placeholder, replace with your actual image

const FastestFoodDelivery = () => {
    return (
        <section className="relative bg-green  text-white py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center justify-between">
                <div className="lg:w-1/2">
                    <h2 className="text-4xl font-extrabold mb-4">
                        Fastest food <span className="text-orange-600">Delivery</span> in town
                    </h2>
                    <p className="text-lg mb-4">
                        Get your choice order fresh, steamy hot in 20mins break time.
                    </p>
                    <ul className="space-y-2">
                        <li className="flex items-center">
                            <span className="w-6 h-6 inline-block rounded mr-2">
                                <img src={droneImage} alt="Air Delivery" />
                            </span>
                            <span>Air delivery</span>
                        </li>
                        <li className="flex items-center">
                            <span className="w-6 h-6 inline-block mr-2">
                                <img src={droneImage} alt="Automated" />
                            </span>
                            <span>Automated</span>
                        </li>
                    </ul>
                </div>
                <div className="lg:w-1/2 flex justify-center mt-8 lg:mt-0">
                    <img src={pizzaImage} alt="Pizza" className="w-3/4 h-auto" />
                </div>
            </div>
        </section>
    );
};

export default FastestFoodDelivery;
