import React, { useState } from 'react';
import { FaShippingFast, FaShoppingBag, FaStore } from 'react-icons/fa';
import mainsection from "../assets/women1.webp";
import { FaPlay } from 'react-icons/fa';

const MainSection = () => {
    const features = [
        {
            icon: <FaShippingFast className="text-white text-3xl" />,
            title: 'Fast delivery',
            description: 'Promised delivery within 30 mins',
        },
        {
            icon: <FaShoppingBag className="text-white text-3xl" />,
            title: 'Pick up',
            description: 'Pickup delivery at your doorstep',
        },
        {
            icon: <FaStore className="text-white text-3xl" />,
            title: 'Dine in',
            description: 'Enjoy your food fresh and crispy',
        },
    ];

    const circle = {
        borderRadius: '80px',
    }



    return (

        <section className="bg-white py-2">
            <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
                <div className="md:w-1/2">
                    <h1 className="text-5xl md:text-5xl font-bold mb-4">
                        Fastest <br /> <span className="text-red">Delivery & <br /></span> Easy <span className="text-green">Pickup</span>
                    </h1>
                    <p className="text-gray-600 mb-6">
                        When you are too lazy to cook, we are just a click away!
                    </p>
                    <div className="flex items-center space-x-4 mb-6 px-24">
                        <div className="flex">
                            <input
                                type="text"
                                placeholder="Find Restaurants"
                                className="bg-gray-200 text-dark px-4 py-2 rounded-l-md w-56"
                            />
                            <button className="bg-green text-white px-4 py-2 rounded-r-md">Search</button>
                        </div>
                        <div className="flex items-center cursor-pointer space-x-2">
                            <div className="relative flex items-center justify-center w-12 h-12 bg-green" style={circle}>
                                <FaPlay className="text-white " />
                            </div>
                            <span className="text-xl font-semibold">How to order</span>
                        </div>

                    </div>
                </div>
                <div className=" flex justify-center md:justify-end ">
                    <img src={mainsection} alt="Fast Delivery" className="w-2/3 mt-28 md:w-full rounded-full" />
                </div>
            </div>


            <div className="container mx-auto flex flex-col md:flex-row items-center justify-between mt-8 px-20 ">
                {features.map((feature, index) => (
                    <div key={index} className="flex items-center mb-4 md:mb-0 md:mr-4">
                        <div className="flex items-center justify-center w-12 h-12 bg-green rounded-full mr-4">
                            {feature.icon}
                        </div>
                        <div>
                            <h3 className="text-xl font-bold">{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    </div>
                ))}
            </div>

        </section>

    );
};

export default MainSection;




// import React, { useState } from 'react';
// import { FaShippingFast, FaShoppingBag, FaStore, FaPlay } from 'react-icons/fa';
// import mainsection from "../assets/women1.webp";

// const MainSection = () => {
//     const features = [
//         {
//             icon: <FaShippingFast className="text-white text-3xl" />,
//             title: 'Fast delivery',
//             description: 'Promised delivery within 30 mins',
//         },
//         {
//             icon: <FaShoppingBag className="text-white text-3xl" />,
//             title: 'Pick up',
//             description: 'Pickup delivery at your doorstep',
//         },
//         {
//             icon: <FaStore className="text-white text-3xl" />,
//             title: 'Dine in',
//             description: 'Enjoy your food fresh and crispy',
//         },
//     ];

//     return (
//         <section className="bg-white py-2">
//             <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
//                 <div className="md:w-1/2 flex flex-col items-start md:items-start">
//                     <h1 className="text-5xl md:text-5xl font-bold mb-4">
//                         Fastest <br /> <span className="text-red-600">Delivery & <br /></span> Easy <span className="text-green-600">Pickup</span>
//                     </h1>
//                     <p className="text-gray-600 mb-6">
//                         When you are too lazy to cook, we are just a click away!
//                     </p>
//                     <div className="flex items-center space-x-4 mb-6 w-full">
//                         <div className="flex flex-grow">
//                             <input
//                                 type="text"
//                                 placeholder="Find Restaurants"
//                                 className="bg-gray-200 text-dark px-4 py-2 rounded-l-md w-full"
//                             />
//                             <button className="bg-green-600 text-white px-4 py-2 rounded-r-md">Search</button>
//                         </div>
//                         <div className="flex items-center space-x-2">
//                             <div className="relative flex items-center justify-center w-12 h-12 bg-green-600 rounded-full">
//                                 <FaPlay className="text-white cursor-pointer" />
//                             </div>
//                             <span className="text-xl font-semibold">How to order</span>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="md:w-1/2 flex justify-center md:justify-end mt-8 md:mt-0">
//                     <img src={mainsection} alt="Fast Delivery" className="w-2/3 md:w-full rounded-full" />
//                 </div>
//             </div>

//             <div className="container mx-auto flex flex-col md:flex-row items-center justify-between mt-8">
//                 {features.map((feature, index) => (
//                     <div key={index} className="flex items-center mb-4 md:mb-0 md:mr-4">
//                         <div className="flex items-center justify-center w-12 h-12 bg-green-600 rounded-full mr-4">
//                             {feature.icon}
//                         </div>
//                         <div>
//                             <h3 className="text-xl font-bold">{feature.title}</h3>
//                             <p>{feature.description}</p>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </section>
//     );
// };

// export default MainSection;

