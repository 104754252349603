

// // src/components/Header.js
// import React, { useState } from 'react';
// import { FaLeaf, FaBox, FaFileInvoiceDollar, FaInfoCircle, FaSearch } from 'react-icons/fa';
// import { MdOutlineAccessTime } from 'react-icons/md';
// import SimpleBar from 'simplebar-react';
// import 'simplebar/dist/simplebar.min.css';
// import logo from "../assets/logo1.webp";
// import user from "../assets/profile.webp";

// const Header = () => {
//     const [open, setOpen] = useState(true);
//     const [menuOpen, setMenuOpen] = useState(false);
//     const [searchInput, setSearchInput] = useState('');

//     const menus = [
//         { title: "Menu", link: "/menu", icon: <FaLeaf /> },
//         { title: "Offers", link: "/offers", icon: <FaBox /> },
//         { title: "Feeds", link: "/feeds", icon: <FaFileInvoiceDollar /> },
//         { title: "Services", link: "/services", icon: <MdOutlineAccessTime /> },
//         { title: "Restaurants", link: "/restaurants", icon: <FaInfoCircle /> },
//     ];

//     const handleInputChange = (event) => {
//         setSearchInput(event.target.value);
//     };

//     return (
//         <header className="bg-light-grey text-black p-4">
//             <div className="container mx-auto flex justify-between items-center">
//                 <div className="text-2xl font-bold">
//                     {/* Meal Monkey<span className="text-red-500">.</span> */}
//                     <img src={logo} alt="logo" className="h-20 w-auto" />
//                 </div>
//                 <nav className="hidden md:flex space-x-4">
//                     {menus.map((menu, index) => (
//                         <a key={index} href={menu.link} className="hover:text-green font-bold">
//                             {menu.title}
//                         </a>
//                     ))}
//                 </nav>
//                 <div className="flex items-center space-x-4">
//                     <input
//                         type="text"
//                         value={searchInput}
//                         onChange={handleInputChange}
//                         placeholder="Search..."
//                         className="hidden md:block bg-white  text-dark px-3 py-1 rounded"
//                     />
//                     <img
//                         src={user}
//                         alt="User"
//                         className="w-10 h-10 rounded-full"
//                     />
//                     <a href="#" className="hover:text-green hidden md:block">Bhuvanesan S</a>
//                     <button
//                         className="md:hidden text-black"
//                         onClick={() => setMenuOpen(!menuOpen)}
//                     >
//                         <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
//                         </svg>
//                     </button>
//                 </div>
//             </div>
//             {menuOpen && (
//                 <nav className="md:hidden bg-light-grey p-4">
//                     {menus.map((menu, index) => (
//                         <a key={index} href={menu.link} className="block py-2 hover:text-green font-bold">
//                             {menu.title}
//                         </a>
//                     ))}
//                 </nav>
//             )}
//         </header>
//     );
// };

// export default Header;



// src/components/Header.js
import React, { useState } from 'react';
import { FaLeaf, FaBox, FaFileInvoiceDollar, FaInfoCircle, FaSearch } from 'react-icons/fa';
import { MdOutlineAccessTime } from 'react-icons/md';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import logo from "../assets/logo1.webp";
import user from "../assets/profile.webp";

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    const menus = [
        { title: "Menu", link: "/menu", icon: <FaLeaf /> },
        { title: "Offers", link: "/offers", icon: <FaBox /> },
        // { title: "Feeds", link: "/feeds", icon: <FaFileInvoiceDollar /> },
        { title: "Services", link: "/services", icon: <MdOutlineAccessTime /> },
        { title: "Restaurants", link: "/restaurants", icon: <FaInfoCircle /> },
    ];

    const handleInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    return (
        <header className="bg-light-grey text-black p-4 fixed w-full top-0 left-0 z-10">
            <div className="container mx-auto flex justify-between items-center">
                <div className="text-2xl font-bold">
                    <img src={logo} alt="logo" className="h-20 w-auto" />
                </div>
                <nav className="hidden md:flex space-x-4">
                    {menus.map((menu, index) => (
                        <a key={index} href={menu.link} className="hover:text-green font-bold">
                            {menu.title}
                        </a>
                    ))}
                </nav>
                <div>
                    <button className='bg-green text-white w-52 rounded text-xl hover:bg-white hover:text-green hover:border-2 hover:border-green  h-10 '>Partner With Us</button>
                </div>
                <div className="flex items-center space-x-4">
                    <input
                        type="text"
                        value={searchInput}
                        onChange={handleInputChange}
                        placeholder="Search..."
                        className="hidden md:block bg-white text-dark px-3 py-1 rounded"
                    />
                    <img
                        src={user}
                        alt="User"
                        className="w-10 h-10 rounded-full"
                    />
                    <a href="#" className="hover:text-green hidden md:block">Bhuvanesan S</a>
                    <button
                        className="md:hidden text-black"
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>
            </div>
            {menuOpen && (
                <nav className="md:hidden bg-light-grey p-4">
                    {menus.map((menu, index) => (
                        <a key={index} href={menu.link} className="block py-2 hover:text-green font-bold">
                            {menu.title}
                        </a>
                    ))}
                </nav>
            )}
        </header>
    );
};

export default Header;

