import React from 'react';
import { FaPinterestP, FaInstagram, FaTwitter, FaFacebookF } from 'react-icons/fa';
import logo from "../assets/logo1.webp";

const Footer = () => {
    return (
        <footer className="bg-pink-100 py-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-2 sm:grid-cols-4 gap-8">
                <div>
                    {/* <h3 className="text-xl font-bold text-red-600">Fooodish</h3> */}
                    <img src={logo} alt="" />
                    <p className="mt-2 text-gray-600">Continue Genie 2024 all rights reserved</p>
                    <h4 className="mt-6 text-lg font-bold text-red-600">Follow Us On</h4>
                    <div className="flex space-x-4 mt-4 justify-center ">
                        <FaPinterestP className="text-gray-600 text-2xl hover:text-green" />
                        <FaInstagram className="text-gray-600 text-2xl hover:text-green" />
                        <FaTwitter className="text-gray-600 text-2xl hover:text-green" />
                        <FaFacebookF className="text-gray-600 text-2xl hover:text-green" />
                    </div>
                </div>
                <div>
                    <h3 className="text-xl font-bold text-green">Menu</h3>
                    <ul className="mt-2 space-y-2 cursor-pointer">
                        <li className="text-gray-600">Home</li>
                        <li className="text-gray-600">Offers</li>
                        <li className="text-gray-600">Service</li>
                        <li className="text-gray-600">About Us</li>
                    </ul>
                </div>
                <div>
                    <h3 className="text-xl font-bold text-green">Information</h3>
                    <ul className="mt-2 space-y-2">
                        <li className="text-gray-600">Menu</li>
                        <li className="text-gray-600">Quality</li>
                        <li className="text-gray-600">Make a Choice</li>
                        <li className="text-gray-600">Salad With Vegetable</li>
                        <li className="text-gray-600">Fast Delivery</li>
                        <li className="text-gray-600">Subscribe</li>
                    </ul>
                </div>
                <div>
                    <h3 className="text-xl font-bold text-green">Contact</h3>
                    <ul className="mt-2 space-y-2">
                        <li className="text-gray-600"> +91 8667211896</li>
                        <li className="text-gray-600">Get In Touch With Us</li>
                        <li className="text-gray-600">getintouch@thegenie.in</li>
                        <li className="text-gray-600">GENERIC TECHNOLOGIES PRIVATE LIMITED

No.32, Arcot Road, 

Virugambakkam,

Chennai - 600092.

India</li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
